import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  const drafts = [];

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Writing" />
      <h1 className="main-heading">Writing</h1>
      <ul style={{ listStyle: "none" }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || post.fields.slug;
          if (
            post.frontmatter.date !== "Invalid date" &&
            !drafts.includes(title)
          ) {
            return (
              <li className="post-link">
                <Link to={post.fields.slug} style={{ textDecoration: "none" }}>
                  {title}
                  <p className="post-date">{post.frontmatter.date}</p>
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
